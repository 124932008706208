import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import Seo from '../components/Seo'
import Faq from '../components/Faq/index'
import FooterImages from '../components/Blocks/FooterImages'
import { Helmet } from 'react-helmet'

const PageTemplate = ({ title, content, faq, footerImages }) => (
  <div className="w-full">
    <div
      className={'transition-all page-content'}
      dangerouslySetInnerHTML={{ __html: content }}
    />
    <FooterImages items={footerImages} />
    <div className="h-16 w-full" />
    {faq?.title && <Faq title={faq?.title} items={faq?.faqs} />}
  </div>
)

const EzLease = ({ data, pageContext }) => {
  const { wpPage: page } = data

  return (
    <Layout pageContext={pageContext}>
      <Seo seo={page.seo} />
      <Helmet>
        <script type="application/ld+json">
          {`{"@context":"https://schema.org","@type":"FAQPage","mainEntity":[{"@type":"Question","name":"What is the D&M EZ Lease?","acceptedAnswer":{"@type":"Answer","text":"Our EZ Lease is the most consumer-friendly lease offered today. We have the lowest rates of any consumer lease on the market. We will find the exact vehicle you are looking for and aim to offer a world class experience.For more on this easy process visit our Easy Leasing Process"}},{"@type":"Question","name":"What is GAP insurance?","acceptedAnswer":{"@type":"Answer","text":"In the event a lease car is totaled or stolen GAP insurance pays the difference between the insurance settlement and the contractual payoff. At D&amp;M Leasing, GAP insurance is included on all car leases at no extra cost to you."}},{"@type":"Question","name":"Can I lease a car over the phone?","acceptedAnswer":{"@type":"Answer","text":"At D&amp;M buy, lease, or trade your car all over the phone. Simply fill out our online quote form and one of our friendly representatives will contact you. We will move at your pace. Once you are ready to lease, D&amp;M will deliver your new vehicle to your home, office, or wherever is most convenient."}},{"@type":"Question","name":"Will I be charged if I go over my lease mileage limit or decide to terminate my lease early?","acceptedAnswer":{"@type":"Answer","text":"Don&#8217;t worry about early termination fees or penalties at D&amp;M. We will customize your lease agreement and offer flexible yearly mileage plans. If you do decide to terminate your car lease early, you won&#8217;t pay early termination fees. "}}]}`}
        </script>
      </Helmet>
      <PageTemplate
        title={page.title}
        content={page.content}
        faq={page.faq}
        footerImages={page.footerImages.items || []}
      />
    </Layout>
  )
}

export default EzLease

export const pageQuery = graphql`
  query EzLease($id: String!) {
    wpPage(id: { eq: $id }) {
      title
      content
      seo {
        fullHead
        schema {
          raw
        }
      }
      footerImages {
        items {
          image {
            mediaItemUrl
          }
          buttonLink {
            target
            title
            url
          }
          buttontext
        }
      }
      faq {
        title
        faqs {
          body
          title
        }
      }
    }
  }
`
